.uploads {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    gap: 8px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.section_title {
    display: flex;
    align-self: center;
    font-weight: 700;
}

.buttons {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #3D5AD0;
    color: #ffffff;
    align-items: center;
}