.title {
  display: flex;
  justify-content: center;
  white-space: nowrap;
  color: #fff;
}

.title_content {
  position: relative;
  display: inline-block;
  padding-bottom: 5px;
  cursor: pointer;
  font-weight: 600;
  font-size: 19px;
  color: rgb(0, 162, 255);
}

.aboutus_text {
  font-size: 15px;
  color: #fff;
}

.title_content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner_content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile_leftcontent {
  display: none;
}

.inner_content {
  color: #fff;
}

.abouttitle {
  border: 2px solid white;
  display: flex;
  flex-direction: column;
}

.side_content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 8px
    /* align-items: center; */
}

@media (max-width: 768px) {
  .main {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 3px solid blue;
    overflow: hidden;
    overflow-y: auto;
  }

  .inner_content {
    display: flex;
    color: black;

  }

  .title h1 {
    font-size: 24px;
    white-space: wrap;
    color: black;
    text-align: center;
  }

  .animate_character {
    text-transform: uppercase;
    background-image: linear-gradient(-225deg,
        #231557 0%,
        #44107a 29%,
        #ff1361 67%,
        #fff800 100%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: 190px;
  }

  @keyframes textclip {
    to {
      background-position: 200% center;
    }
  }

  .abouttitle h2 {
    font-size: 20px;
  }

  .aboutus_text p,
  .inner_content {
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    font-size: 16px;
  }
}