.accounts_card_main {
  width: 70%;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.accounts_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  padding:5px;
  background-color: #f7f7f7;
  /* background-color: aqua !important; */
  overflow: hidden;
}

.card_content {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.card_content_div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  padding: 10px;
  padding-left: 5em;
}

.content_title {
  /* display: flex; */
  flex-direction: column;
  float: right;
  width: 50%;
  padding: 8px;
  font-weight: 600;
  /* justify-content: center;
    align-items: center; */
}
.content {
  width: 50%;
  float: right;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
@media (min-width: 490px) and (max-width: 1280px) {
  .accounts_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    background-color: #f7f7f7;
    overflow: hidden;
    width: 70%;
  }
  .card_content_div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
}

@media screen and (max-width: 489px) {
  .accounts_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    background-color: #f7f7f7;
    top: 1rem;
  }
  .card_content_div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 0px;
  }
  .accounts_card_main {
   
    padding: 5px;
    display: flex;
    justify-content: center;
   
  }
}
