.main_con {
    height: 100vh;
    display: flex;
    flex-direction: column;

}

.account_card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    width: 100%;

    padding-bottom: 20px;

}

.chat_card {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    padding-bottom: 20px;
}

@media screen and (max-width:950px) {
    .account_card {
        margin-top: 11%
    }
}