.main {
  /* display: flex;
    flex-direction: column;
    align-items: center;
        justify-content: center;
    align-content: center;
    padding: 10%;
        height: 50%;
    width: 50%; */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
}

.cadconsdroid {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  color: #3d5ad0;
  font-size: 23px;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 50%; */
  width: 30%;
  border: 1px solid blue;
  border-radius: 30px;
  gap: 5px;
  background-image: url("../../../assets/Building.png");
  background-size: cover;
}

.forgotpassword_button {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: #3d5ad0; */
  color: #fff;
  background-color: #3d5ad0;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.errors {
  color: red;
}

.button_style {
  padding: 10px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 10px;
}

.title {
  padding-left: 10%;
  /* text-align: center; */
}

@media (max-width: 768px) {
  .form {
    width: 90%;
    /* padding: 10px; */
  }

  .title {
    white-space: nowrap;
  }
}

@media (max-width: 480px) {
  .form {
    width: 90%;
  }
}