.main_con {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card {
    width: 80%;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    margin-bottom: 10px;
    overflow: hidden;
}

.card_con {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-color: #f7f7f7;
    padding: 5px;
}

.card_label {
    float: right;
    font-weight: 700;
    width: 50%;
    padding: 2px;
}

.card_value {
    float: right;
    flex-wrap: wrap;
    width: 50%;

}

.button {
    background-color: #3d5ad0;
    border: none;
    padding: 6px;
    width: 30%;
    border-radius: 8px;
    border: 2px solid #f7f7f7;
    color: white;
}