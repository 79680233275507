.projectCardMain {
  padding: 1em;
}

.cardMain {
  padding-top: 20px;
}

.projectCardContainerCard {
  padding: 5px;
}

.projectCardContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 1em;
  border: 3px solid #dddddd;
  border-radius: 25px;
}

.containerDetails {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.siteplanContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.containerPlan {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.containerPlan1 {
  display: flex;
  flex-direction: row;
  gap: 5rem;
  padding-top: 3px;
}

.containerDetailsPlan {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.containerButton {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 5px;
  padding-top: 3px;
}

.UploadCard {
  display: flex;
  gap: 10px;
  flex-direction: column;
  flex-wrap: wrap;
}

.templateUpload {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.uploadButton {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 3px;
}

.yesButton {
  border: 1px solid #3d5ad0;
  padding: 3px 10px;
  border-radius: 10px;
}

@media screen and (min-width: 598px) and (max-width: 758px) {
  .containerButton {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-around;
    gap: 5px;
    padding-top: 15px;
  }
}

@media screen and (max-width: 597px) {
  .containerPlan1 {
    display: flex;
    flex-direction: column;
    gap: 1rem !important;
  }

  .siteplanContainer {
    display: flex;
    flex-direction: column;
  }

  .containerDetailsPlan {
    display: flex;
    flex-direction: row;
    gap: 2px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  /* .containerButton{
        display: flex !important;
        flex-direction: row !important;
        justify-content: space-around;
        gap: 5px;
        padding-top: 15px;
    } */
}
