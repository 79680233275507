.onGoingMain {
    padding: 5px 25px;
}

.custom_message {
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}