.sitePlan {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
  padding: 5px;
}
.sitePlanFloorDetails {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
  padding: 5px;
}

.sitePlanTitle {
  display: flex;
  flex-direction: row;
  gap: 5rem;
}

.siteStatus {
  color: white;
  padding: 5px;
  border-radius: 5px;
  background-color: #3d5ad0;
}

.siteContentDetails {
  display: flex;
  gap: 1rem;
  padding: 7px;
  flex-wrap: wrap;
}

.siteContentDetailsBtn {
  display: flex;
  gap: 1rem;
  padding: 5px;
  flex-wrap: wrap;
  background-color: #3d5ad0;
  color: white;
  border-radius: 10px;
  cursor: pointer;
}

.siteMainTitle{
color: #3d5ad0;
display: flex;
justify-content: center;
align-items: center;
font-size: larger;
font-weight: bolder;
padding-top: 10px;
padding-bottom: 15px;
}

.siteTitle {
  color: #3d5ad0;
  font-weight: bolder;
  font-size: medium;
  padding: 1px;

}

.siteDetailsSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10px;
  gap: 2rem;
}

.siteContentDetailsCardMain {
  padding: 5px;
}

.siteContentDetailsCard {
  display: flex;
  gap: 1rem;
  padding: 5px;
  flex-wrap: wrap;
  flex-direction: column;
  border: 2px solid #3d5ad0;
  border-radius: 8px;
  /* background-color: #3d5ad0; */
}

.SiteDocumentMain{
flex-direction: column;
display: flex;
/* align-items: center; */
border: 2px solid #3d5ad0;
border-radius: 10px;
}

@media screen and (max-width: 767px) {
  .sitePlan {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
  }

  .siteContentDetails {
    display: flex;
    gap: 1rem;
    padding: 10px;
    flex-wrap: wrap;
  }

}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .sitePlan {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
  }

  .siteContentDetails {
    display: flex;
    padding: 10px;
    flex-wrap: wrap;
  }

  .siteTitle {
    padding: 5px;
  }
}