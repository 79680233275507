.input_con {
    gap: 1em;
    position: relative;
    display: flex;
    justify-content: space-between;
}

label {
    font-size: 1.2rem;
}

input {
    font-family: "Roboto", sans-serif;
    border-radius: 5px;
}

.show_password_icon {
    position: absolute;
    top: 0.6em;
    right: .8em;
    z-index: 1;
    cursor: pointer;
}

.img_tag {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}