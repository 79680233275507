.signup_container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-items: center;
  /* gap: 5px; */
  justify-content: center;
  padding: 1em;

}
/* *{
  overflow: hidden;
  overflow-y: auto;
} */
.signup_heading {
  font-size: 30px;
  font-weight: 600;
  padding: 20px;
}

.signup_subheading {
  font-size: 14px;
  padding: 1em;
}

.signup_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
}

.signupform_linetype1 {
  display: flex;
  width: 100%;
  padding: 10px;
  font-weight: bold;
}

.signupbox_inputfield_type1 {
  display: block;
  width: 90%;
  background-color: transparent;
  border: none;
  border: 1px solid gray;
  position: relative;
  height: 40px;
  border-radius: 5px;
  padding: 0px 10px;
}
.inputfield_title {
  padding-bottom: 5px;
}
.signupform_linetype1 > div {
  width: 100%;
}

.signupbox_inputfield_type2 {
  display: block;
  width: 80%;
  background-color: transparent;
  border: none;
  border: 1px solid gray;
  position: relative;
  height: 40px;
  border-radius: 5px;
  padding: 0px 10px;
}

.signupbox_inputfield_type3 {
  display: block;
  width: 95%;
  background-color: transparent;
  border: none;
  border: 1px solid gray;
  position: relative;
  height: 40px;
  border-radius: 5px;
  padding: 0px 10px;
}

.signupbox_select {
  background-color: transparent;
  height: 40px;
  border: none;
  border: 1px solid gray;
  border-radius: 5px;
  width: 96%;
}

.signupbox_inputfile {
  width: 95%;
}

.signupform_linetype2 {
  display: flex;
  width: 100%;
  padding: 10px;
  /* background-color: #3A833A; */
}

.signupform_linetype2 > div {
  padding: 10px;
}

.signupform_linetype2 > div:nth-child(2) {
  padding: 10px;
}

.form_control {
  width: 93%;
  height: 70%;
}

.option_title {
  font-weight: bold;
  padding-bottom: 5px;
}

.button {
  background-color: #3a833a;
  padding: 10px;
  width: 20%;
  border: 2px solid white;
  border-radius: 8px;
  color: white;
  font-size: medium;
}

@media screen and (max-width: 768px) {
  .signup_box {
    padding: 10px;
    border: 2px solid #3a833a;
    display: flex;
    flex-direction: column;
  }
  .signupbox_inputfile {
    width: 90%;
  }
  .signup_container {
    padding: 10px;
  }

  .signupform_linetype1 {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .signupform_linetype2 {
    display: flex;
    flex-direction: column;
  }
  .signupbox_select {
    background-color: transparent;
    height: 40px;
    border: none;
    border: 1px solid gray;
    border-radius: 5px;
    width: 93%;
  }
  .signupbox_inputfield_type2 {
    display: block;
    width: 90%;
    background-color: transparent;
    border: none;
    border: 1px solid gray;
    position: relative;
    height: 40px;
    border-radius: 5px;
    padding: 0px 10px;
  }
  .signupbox_inputfield_type3 {
    display: block;
    width: 89%;
    background-color: transparent;
    border: none;
    border: 1px solid gray;
    position: relative;
    height: 40px;
    border-radius: 5px;
    padding: 0px 10px;
  }

  .form_control {
    width: 93%;
    height: 35px;
  }
}
