.button {
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  padding: 0.8rem 2rem;
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  font-weight: normal;
  background-color: #000000;
  gap: 1px;
}

.icon {
  justify-content: center;
  align-items: center;
  margin: 3px;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.2em 0.5em;
}

.text {
  /* Styles for the button text */
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.color1 {
  background-color: #3D5AD0;
  color: #ffffff;
}

.color2 {
  background-color: #6983ec;
  color: #ffffff;
}

.color3 {
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #9d9d9d;
}

.color4 {
  background-color: #e8d500;
  color: #000000;
}

.narrow {
  padding: 0.2rem 0.04rem;
  width: 100%;
}

.wide {
  padding: 0.5rem;
}