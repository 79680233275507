.NewProjectSty {
  padding: 1em 5em;
  display: block;
}

.NewProjectCardSty {
  display: none;
}

@media screen and (max-width: 767px) {
  .NewProjectSty {
    display: none;
  }

  .NewProjectCardSty {
    display: block;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .NewProjectSty {
    display: none;
  }

  .NewProjectCardSty {
    display: block;
  }
}