.con {
  display: flex;
  padding: 2%;
  gap: 2rem;
}

.table {
  flex: 1;
  width: 90%;
  overflow: scroll;
}

table> :hover {
  color: gray;
  cursor: pointer;
}

.image {
  width: 300px;
  height: 300px;
}

@media screen and (max-width: 968px) {
  .con {
    flex-wrap: wrap-reverse;
  }
}