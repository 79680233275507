.main_con {
  height: 99.5vh;
  background-color: #3d5ad0;
  padding: 0.1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: flex-end; */
}

.button {
  margin-bottom: 0.8rem;
  padding: 1rem;
}

.brand_card {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.logo_line {
  width: 100%;
}

.links_con {
  display: flex;
  flex-direction: column;
}

.links_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
}

/* .links_main> :last-child {
  border-top: 1px solid var(--light);
  margin-top: 1.3rem;
} */