.main_con {
    height: 100vh;
    background-color: #3D5AD0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
}

.link_style {
    display: flex;
    text-decoration: NONE;
    color: white;
    padding: 25px;
}

.link_style:hover {
    background-color: #839bfe;
}

.side_con {
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* height: 80%; */
}

.button {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding-left: 5%;
    padding-bottom: 5%;
    margin-top: auto;

}

.img_con {
    padding: 20px;

}