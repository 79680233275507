.main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100vh;

}

.login-hamburger {
  display: none;
}

.left_content {
  background: url("../../../assets/Cad_Background.png");
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
}

.leftContent_overlay {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.left_content,
.right_content {
  flex: 1;
}

.left_content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.right_content {
  display: flex;
  align-items: center;
  /* padding-left: 10px; */
  /* float: right; */
  width: 50%;
}

.right_cadform {
  margin: 0 auto;
  text-align: center;
}

.tabs {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  gap: 20px;
  margin-right: 20px;
  padding-top: 5px;
  /* float: right; */
}

.tab {
  /* position: relative;
    padding: 10px; */
  cursor: pointer;
}

.tab.active {
  border-bottom: 2px solid blue;
}

.tab::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  transform: scaleX(0);
  transition: transform 0.5s ease, background-color 0.3s ease;
}

.tab.active::after,
.tab:hover::after {
  transform: scaleX(1);
  background-color: #3d5ad0;
}

.mobile_leftcontent {
  display: none;
}

.title h1 {
  justify-content: center;
  display: flex;
  color: #fff;
  padding-left: 100px;
  font-size: 24px;
}

.cadform button {
  padding: 10px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.dontaccount p,
.dontaccount a {
  color: #333333;
}

h4 {
  font-weight: bold;
  color: #ffff;
}

.about,
.why {
  cursor: pointer;
}

h4:hover {
  text-decoration: underline;
}

.mobile_leftcontent {
  display: none;
}

@media (max-width: 768px) {
  .main {
    margin-top: 5%;
    display: flex !important;
    flex-direction: column-reverse !important;
    flex-wrap: wrap;
    height: 100vh;
  }

  .left_content {
    background: none;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .left_content::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 1;
  }

  /* .leftContent_overlay {
    background: none;
    display: none;
  } */
  /* .left_content{
    background: none;
    display: none;
  } */
  .tabs {
    background-color: aqua;
    display: none;
  }

  .mobile_leftcontent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .right_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
  }
}