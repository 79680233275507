.button {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding: 2%;
  margin: 5% 10%;
}

@media screen and (max-width:600px) {
  .button {
    margin-top: 12% !important;
  }
}