.pdContainer {
    padding: 2rem;
}

.projectDetailsContainer {
    border: 1px solid #ddd;
    border-radius: 25px;
}

.siteDetailContent {
    padding: 2rem;
}

.siteDetailsSection {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 10px;
    gap: 2rem;
}

.planAccDelete {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 5px;
}

.accContainer {
    padding: 8px;
    border-radius: 10px;
    border: 2px solid #3d5ad0;
    font-weight: 500;
    cursor: pointer;
}