.main_con {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin-top: 10px;
    border-radius: 5px;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    /* background-color: #f7f7f7; */
    overflow: hidden;
    width: 100%;
    /* max-width: 70%; */
    /* padding: 10px; */
}

.label_text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 15px;
    padding-left: 10px;
    font-weight: 500;
    width: 50%;
}

.card {
    display: flex;
    flex-direction: column;
    width: 60%;
    justify-content: center;
    align-items: center;
    background-color: #f0efef;
    border-radius: 10px;
}

.card_title {
    display: flex;
    background-color: #d9d9d9;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    ;
    /* padding: 5px; */

}

.card_content {
    display: flex;
    /* flex-direction: column; */
    /* background-color: red; */
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 5px;

}

.button {
    padding-left: 35%;
}

.errors {
    color: rgb(248, 69, 69)
}

@media screen and (max-width: 1533px) {
    .main_con {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;
        margin-top: 10px;
        border-radius: 5px;
    }

    .card {
        display: flex;
        flex-direction: column;
        /* width: 60%; */
        justify-content: center;
        align-items: center;
        /* background-color: green; */
        border-radius: 10px;
        padding: 20px
    }

    .card_title {
        display: flex;
        /* background-color: #d9d9d9; */
        width: 100%;
        justify-content: center;
        align-items: center;
        /* padding: 5px; */
        border-radius: 10px;

    }

    @media screen and (min-width:751px) and (max-width:1543px) {
        .main_con {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 70% !important;
            margin-top: 10px;
            border-radius: 5px;
        }
    }

}