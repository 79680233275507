.title {
  margin-left: 5%;
}

.template {
  margin-top: 2rem;
}

.custom_message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}