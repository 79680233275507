.main_con {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.account_data_card {
  display: none;
}

.account_card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
}

.account_table {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 900px) {
  .account_table {
    display: none;
  }

  .account_data_card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .account_card {
    margin-top: 10%;
  }
}
