.foldername {
  display: flex;
  flex-direction: column;
  color: black;
  font-size: large;
  width: 100px;
  white-space: normal;
  cursor: pointer;
}

p {
  word-wrap: break-word;
  word-break: break-all;
}

.foldername img {
  width: 60px;
  height: 50px;
}
.folderFile {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tempFolder{
display: flex;
flex-direction: row;
align-items: center;
}

.card {
  padding: 10px;
}
.card1 {
  padding: 5px;
  border: 2px solid #ddd;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}
.card2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

.create_sec {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 3%;
}

.create_text {
  display: flex;
  background-color: #3d5ad0;
  padding: 10px;
  color: white;
  margin-left: 5%;
  border-radius: 5px;
}

@media screen and (max-width: 360px) {
  .card {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 10%;
    flex-wrap: wrap;
  }
}