h1 {
  font-weight: bold;
  font-size: 23px;
  display: flex;
}

.formpage {
  border: 1px solid #463bfa;
  border-radius: 20px;
  margin-top: 5%;

  /* height: 10%;
  width: 100%; */
  /* position: relative; */
}

.cadform {
  background-image: url("../../../../../assets/Building.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.input {
  border-radius: 0px;
  border-bottom: 1px solid black;
  background-color: transparent;
  position: relative;
  /* width: 200% !important; */
}

.title {
  justify-content: center;
  color: rgb(61, 90, 208);
}

.inputs {
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  align-items: center;
  padding: 20px;
}

.errors {
  display: flex;
  justify-content: flex-end;
  color: red;
}

.forget {
  font-size: 13px;
}

.forget:hover {
  text-decoration: underline;
  color: blue;
}

.donthaveac {
  padding: 20px;
  color: black;
}

.login_button {
  background-color: #3d5ad0;
  color: white;
  width: 40%;
  border: 2px solid white;
  border-radius: 4px;
  padding: 6px 20px;
}

@media (max-width: 768px) {

  .login_button {
    width: fit-content;
  }



}