.links {
  display: flex;
  align-items: center;
  padding: 0.9rem 1.5rem;
  border-radius: 16px;
  color: var(--light);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in;
  text-decoration: none;
  width: 180px;
  position: relative;
  overflow: hidden;
}

.links_icon_name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.links:hover {
  color: var(--light);
  transform: scale(1.05);
}

.links.active {
  color: var(--textactive);
  background-color: "blue";
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(238, 230, 230, 0.838);
}

.module_name {
  color: #ffff;
}