.main_con {
    display: flex;
    flex-direction: column;
}

.text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;
}