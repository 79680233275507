.ham_style {
    padding: 5px;
    /* background-color: red; */
    z-index: 1;
    position: absolute;
    top: 0px;
    /* padding-left:60px; */
    padding-top: 5px;

}

.main_con {
    flex-wrap: nowrap;
    z-index: 2;
    width: 100%;

}