.image {
  width: 30px;
  height: 30px;
}
.maincard {
  padding: 10px;
}
.card {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10%;
  border: 1px solid #ddd;
  border-radius: 10px;
  gap: 1rem;
  padding: 5px;
  width: 70%;
}
.fileName {
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 80%;
  align-items: center;
  padding-left: 5%;
}
.more {
  width: 20%;
  display: flex;
  justify-content: space-around;
}
.button {
  display: flex;
  margin-left: 5%;
  margin-bottom: 2%;
  margin-top: 2%;
}

.text {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 5%; */
}

@media screen and (max-width: 900px) {
  .text {
    /* display:flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%; */
    display: none;
  }
  .button {
    margin-top: 5%;
  }
}
