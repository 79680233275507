.projectCardMain {
  padding: 1em;
}

.projectCardContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 1em;
  border: 3px solid #dddddd;
  border-radius: 25px;
  gap: 1rem;
}

.containerDetails {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.custom_message {
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}