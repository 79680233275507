.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.cadconsdroid {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  color: #3d5ad0;
  font-size: 23px;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 500px; */
  width: 50%;
  /* width: 400px; */
  border: 1px solid blue;
  border-radius: 30px;
  gap: 10px;
  /* background-image: url("../../../assets/Building.png"); */
  background-size: cover;
  padding: 20px;
}

.input {
  width: 80%;
  height: 30px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid blue;


}

.setpasswordinput {
  height: 20%;
  width: 100%;
}

.button {
  /* width: 20%; */
  /* min-width: 40px;  */
  /* max-width: 300px;  */

  /* width: 10%; */
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid blue;
  border-radius: 10px;
  cursor: pointer;
  background-color: transparent;
  text-align: center;
  white-space: nowrap;
}

.errors {
  color: red
}

@media (max-width: 605px) {
  .main {
    justify-content: center;
    align-items: center;
  }

  .form {
    display: flex;
    justify-content: center;
    width: 90%;
    /* gap: 10px; */
    padding: 10px;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

  }

  .button {
    width: 40%;
  }

  .setpasswordinput {
    width: 80%;
  }
}