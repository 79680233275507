.modal {

  position: fixed;

  top: 0;

  left: 0;

  width: 100%;

  height: 100%;

  background-color: rgba(0, 0, 0, 0.5);

  display: flex;

  justify-content: center;

  align-items: center;

  z-index: 2;
  opacity: 0;
  /* Start with 0 opacity */
  transition: opacity 0.3s ease-in-out;
  /* Add a smooth opacity transition */

}



.modal_content {

  background-color: white;

  /* padding: 0.3em; */

  border-radius: 8px;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  display: flex;

  flex-direction: column;

  justify-content: center;

  align-items: center;

  padding: 1em;
  transform: translateY(-50%) scale(0.8);
  /* above code Start with scaled down and translated position */
  transition: transform 0.3s ease-in-out;
  /* above Add a smooth transform transition */

}

.modal.open {
  opacity: 1;
  /* Fully opaque when open */
}

.modal_title {
  display: flex;
  justify-content: space-between;
  height: 30px;
  width: 100%;
}

.modal.open .modal_content {
  transform: translateY(0%) scale(1);
  /* Return to normal position and scale when open */
}

.madl_heading {
  display: flex;
  width: 100%;
  align-self: center;
  cursor: pointer;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0.5rem;

}

.close_button {
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  align-items: flex-end;
  cursor: pointer;

  padding: 0.5rem;

}

.component {

  padding: 1.8em 6em;

  width: 100%;

  height: 100%;

}

.modal_content.div {

  justify-self: flex-end;

}

.modal_content h2 {

  margin-top: 0;

}



.modal_content p {

  margin-bottom: 0;

}