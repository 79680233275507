.main_class {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
}
.accounts_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  background-color: #f7f7f7;
  overflow: hidden;
  width: 50%;
  /* max-width: 70%; */
  /* padding: 10px; */
}

.card_title {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e4e4e4;
  font-weight: 600;
  width: 100%;
  padding: 10px;
}

.card_content {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.card_content_div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  padding: 10px;
}

.accounts_button {
  width: 30%;
}

.content_title {
  /* display: flex; */
  flex-direction: column;
  float: right;
  width: 50%;
  padding: 10px;
  font-weight: 600;
  /* justify-content: center;
    align-items: center; */
}

.content {
  width: 50%;
  float: right;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

@media (min-width: 490px) and (max-width: 1280px) {
  .accounts_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    background-color: #f7f7f7;
    overflow: hidden;
    width: 70%;
  }

  .card_content_div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
}

@media screen and (max-width: 489px) {
  .accounts_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    background-color: #f7f7f7;
    width: 80%;
    top: 1rem;
  }

  .card_content_div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
}
