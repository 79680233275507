.deleteSection{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.deleteSectionBtn{
    display: flex;
    align-items: center;
    flex-direction: row;
gap: 10px;
padding: 5px;
}
