.project_title {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  height: 10em;
}

.user_title {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 1em;
}

@media screen and (min-width: 490px) and (max-width: 900px) {
  .user_title {
    margin-top: 30px;
  }
}

@media screen and (max-width: 490px) {
  .user_title {
    margin-top: 30px;
  }
}