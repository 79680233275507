.custom_message {
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}
.projectBtn{

display: flex;
justify-content: center;
}