html,
body {
  overscroll-behavior-y: auto;
}

.app_container {
  display: flex;
  height: 100vh;
}

.sidebar {
  flex: 0 0 auto;
  background-color: #f4f4f4;
  overflow-y: none;
}

.outlet {
  flex: 1 1 auto;
  overflow-y: auto;
}

.hamburger {
  display: none;
}

@media screen and (min-width: 490px) and (max-width: 900px) {

  .hamburger {
    display: flex;
    position: absolute;
    z-index: 1;
    width: 100%;
    background-color: #3d5ad0;
    height: 40px;

  }
  
   
  .icon {
    padding: 5px;
    display: flex;

    justify-content: center;
  }

  .sidebar {
    display: none;
  }
}

@media screen and (max-width: 489px) {

  .hamburger {
    display: flex;
    position: absolute;
    z-index: 1;
    width: 100%;
    background-color: #3d5ad0;
    height: 40px;
  }

  .icon {
    padding: 5px;
    display: flex;

    justify-content: center;
  }

  .sidebar {
    display: none;
  }
}