.previewpage {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 2px solid black; */
}

.content {
  width: 70%;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: justify;
  /* padding: 20px; */
}

.image {
  display: flex;
  justify-content: center;

  padding: 10px;
  width: 60%;

  .img {
    width: 100%;
    height: auto;
    /* max-width: 100px; */
  }
}

.cadcontent {
  padding: 5px;
}

.title {
  text-align: center;
}

.cad_timing {
  width: 100%;
  padding: 5px;
}

.cadtiming_check {
  width: 100%;
  padding: 5px;
}
.cad_termination {
  padding-left: 3em;
}
.text {
  font-size: 14px;
  font-weight: bold;
}

.buttons {
  display: flex;
  justify-content: space-between;
  padding: 1em;
}

.print_btn {
  padding: 5px;
  background-color: aqua;

}

.print_btn1 {
  background-color: aquamarine;
  border-radius: 5px;
 
}

.bottom_content {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.check {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;

}

@media only screen and (max-width: 768px) {
}
