.main {
  display: flex;
  flex-direction: column;

}

.top {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.left_reason {
  width: 40%;
}

.button {
  display: flex;
  justify-content: center;
  padding-right: 20%;
  padding-top: 10px;
}

.button_content {

  background-color: #3d5ad0;
  border-radius: 8px;
  border-color: white;
  width: 70px;
  height: 30px;
  color: white;
}


input {
  width: 70%;
  /* padding: 5px 10px; */
  padding: 10px;
  background-color: #eeeeee;
  border-radius: 3px;
  border-color: #eeeeee;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.19);
}

.support_supportimage {
  height: 50%;
  width: 50%;
}

textarea {
  height: 70px;
  width: 75%;
  background-color: #eeeeee;
  border-radius: 3px;
  border-color: #eeeeee;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.19);
}

.details_text {
  color: black;
}

.errors {
  color: red;
}

.mailcall {
  display: flex;
  gap: 8px;
}

.contactus {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.contactustext {
  justify-content: center;
  align-items: center;
}

.support_Image {
  display: flex;
  justify-content: center;
}

.bottom {
  padding: 20px;
}

table {
  width: 100%;
  border: 1px groove #e7e7e7;
  table-layout: fixed;
  border-collapse: collapse;
  justify-content: center;
  align-items: center;
}

th,
td {
  table-layout: fixed;
  width: 20%;
  border: 1px solid #e7e7e7;
  text-align: center;
  padding: 5px;
}

th {
  background-color: #eeeeee;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.recenttickets {
  text-align: center;
}

.mobile_support_card {
  display: none;
}

@media screen and (max-width:768px) {
  .main {
    /* background-color: aquamarine; */
  }

  .table {
    display: none;
  }

  .mobile_support_card {

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .top {
    padding: 20px;
    flex-direction: column-reverse;

    gap: 10px;
    align-items: center;
  }

  .left_reason {
    border: 2px solid black;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    color: black;
    /* flex-wrap: wrap; */
    /* background-color: #3d5ad0; */
    width: 100%;
    justify-content: center;
    align-items: center;
  }


  textarea {
    width: 75vw;


  }

  h4 {
    color: black;
  }

  .contactus {
    /* background-color: aqua; */
    display: flex;
    flex-wrap: wrap;
    padding-top: 20%;
    padding: 0%;
  }

  .support_supportimage {
    height: 35%;
    width: 35%;
  }

  .mailcall {
    padding-bottom: 0px;

  }

  .button {
    /* background-color: aqua; */
    width: 100vw;
    padding: 10px;


  }

  .recenttickets {
    padding: 0%;

  }

  .bottom {
    padding: 0%;
  }

  .details_text {

    white-space: nowrap;
  }

  .mailcall {
    gap: 4px;
    font-weight: 200;
    font-style: oblique;
  }


}













/* @media (max-width: 767px) {
  .top {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .left_reason {
    width: 100%;
  }

  .button {
    padding-right: 0;
  }

  .button_content {
    width: 100%;
  }

  input,
  textarea {
    width: 100%;
  }

  .support_supportimage {
    width: 100%;
  }
}

/* Tablet */
/* @media (min-width: 768px) and (max-width: 1023px) {
  .top {
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }

  .left_reason {
    width: 40%;
  }

  .button {
    padding-right: 20%;
    padding-top: 0;
  }

  .button_content {
    width: 70px;
  }

  input {
    width: 70%;
  }

  textarea {
    width: 75%;
  }

  .support_supportimage {
    width: 50%;
    height: 50%;
  }

  .contactus {
    flex-direction: row;
  }
} */

/* Tablet and Desktop */
/* @media (min-width: 768px) {


  .main{
    display: none;
  }
  .bottom{
    display: none;
  }
  .table{
    display: none;
  }
.mobile_support_card{
  display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    width: 100%;

    padding-bottom: 20px;
  } */

/* .contactustext {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  } */
/* } */

/* Desktop */
/* @media (min-width: 1024px) {
  .main {
    flex-direction: row;
    gap: 20px;
  }

  .left_reason {
    width: 40%;
  }

  .button {
    padding-right: 20%;
    padding-top: 0;
  }

  .button_content {
    width: 70px;
  }

  input {
    width: 70%;
  }

  textarea {
    width: 75%;
  }

  .support_supportimage {
    width: 50%;
    height: 50%;
  }

  .contactus {
    flex-direction: row;
  }

  .bottom {
    padding: 20px;
  }

  table {
    width: 100%;
    table-layout: auto;
  }

  th,
  td {
    width: auto;
  }
}  */